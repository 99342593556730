.root, .App, .row, .row > div {
  padding: 0;
  margin: 0;
  background-color: white;
}
.App {
  width: 100%;
}
.App .internetAccess {
  position: fixed;
  z-index: 999;
  width: 100%;
  text-align: center;
}
.App .page-header h1 {
  margin-left: 15px;
}
.App footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 4;
}
.App footer .nav-footer {
  padding: 0 20px;
  font-family: Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif;
  text-align: left;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  background-color: #f5f5f5;
  margin-top: 9px;
  position: relative;
}
.App footer .nav-footer > a {
  min-width: 50px;
  text-align: center;
  text-decoration: none;
  float: right;
  font-weight: regular;
  color: #111;
  font-size: 12px;
  padding: 0 1em;
}
.App footer .nav-footer .copyright {
  margin: 0 auto;
  padding: 0 2em 0 2em;
  font-weight: regular;
  color: #111;
  font-size: 12px;
  padding: 0 1em;
}
@media (max-width: 767px) {
  .App footer .nav-footer {
    height: 60px;
  }
}
.App .btn-primary {
  background-image: linear-gradient(-179deg,#5690FF 0,#387DFF 97%);
  background-repeat: repeat;
  font-weight: 600;
}
.App .btn-success {
  background-image: linear-gradient(-180deg,#58F17B 4%,#16CD6F 93%);
  background-repeat: repeat;
  font-weight: 600;
}
.App .navbar-brand {
  font-weight: bold;
  padding: 0;
}
.App .navbar-brand img {
  height: 50px;
}
.userContent {
  padding: 7px;
  zoom: 1.3;
  margin-left: 5px;
}
.profile {
  padding-left: 10px;
}
.profile button {
  border-color: transparent;
}
.spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}
.App > .row > div > div.lander,
.App > .row > div.PublicContact {
  padding-top: 50px;
}
.App > .row > div.Login {
  padding-top: 80px;
}
.navbar {
  margin-bottom: 5px;
}
.App .navbar-default .navbar-nav>li>a.btn-success,
.App .navbar-default .navbar-nav>li>a.btn-primary {
  color: white;
}
.image-responsive {
  width: 100%;
  height: auto;
}
.help {
	float: right;
	cursor: pointer;
}
