.Home ol.breadcrumb {
  text-align: center;
}
.Home .lander {
  text-align: center;
  margin-top: 52px;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander h2 {
  font-size: 40px;
  font-weight: bold;
}

.Home .lander .jumbotron.home-sentinel {
  background: inherit;
}
.Home .lander .jumbotron.home-sentinel ul {
  text-align: justify;
}

@media (max-width: 767px) {
  .Home .lander .jumbotron.home-sentinel .row > div {
      height: auto;
      padding-top: 20px;
  }
}

.Home .lander .jumbotron .row img {
  cursor: pointer;
}

.Home .lander div {
/*  padding-top: 10px; */
}
.Home .lander div a:first-child {
  margin-right: 20px;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.video-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Home h4 {
	text-align: center;
}
.Home .panel {
	margin: 5px;
}

.Home .row > div {
	padding: 10px;
}

.Home .react-card-front {
  position: relative !important;
}
.Home .react-card-back {
  text-align: center;
}
.Home .loadingImageHome {
  width: 326px;
  height: 231px;
  display: table-cell;
  vertical-align: middle;
}
.Home .hideHomeImage {
  display: none;
}
