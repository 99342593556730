.dashboard-legend {
  margin: 0;
}
.dashboard-legend .legend {
  display: block;
}
.dashboard-legend .legend ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dashboard-legend .legend ul li {
  font-size: 80%;
  list-style: none;
  margin-left: 0;
  line-height: 18px;
  float: left;
}
.dashboard-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 16px;
  width: 30px;
  margin-right: 5px;
  margin-left: 0;
  border: 1px solid #999;
}
.dashboard-legend ul.legend-labels li span.legend-label {
  display: inherit;
  float: inherit;
  height: inherit;
  width: inherit;
  margin-right: inherit;
  margin-left: inherit;
  border: inherit;
  padding-right: 5px;
}
.dashboard-legend ul.legend-labels li span.green {
  background: #58f17b;
}
.dashboard-legend ul.legend-labels li span.yellow {
  background: #FFD733;
}
.dashboard-legend ul.legend-labels li span.red {
  background: #fb2626;
}
.dashboard-legend ul.legend-labels li span.grey {
  background: blue;
}
.dashboard-legend ul.legend-labels li span.black {
  background: #000;
}
