.lastAlerts table tr th {
  line-height: 0.8;
  background-color: #f5f5f5;
}
.lastAlerts table tr td {
  line-height: 0.8;
}
.lastAlerts table tr th .lastUpdate {
  float: right;
  color: blue;
}
