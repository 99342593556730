hr {
	margin-top: 0;
	margin-bottom: 8px;
}
h3 {
	margin-top: 0;
}
.App > .row > div.Support, .App > .row > div.SupportDetail {
	padding: 7px;
}
.Support .btnQuantity {
	margin-bottom: 15px;
}
.Support .tickets {
	padding-bottom: 20px;
}
.Support .newTicket {
	padding-left: 15px;
	display: inline-block;
}
.Support .btn-group > button {
	float: right;
  margin-left: 10px;
}
.Support .btn-success {
	width: 160px;
}
.Support .btn-danger {
	width: 160px;
}
.Support .btn-warning {
	width: 160px;
}
.Support .btn-info {
	width: 160px;
}
.SupportDetail .commentsTitle {
	width: 100%;
}
.SupportDetail .commentsTitle .btn:first-child {
	margin-right: 5px;
}
.SupportDetail .comment {
	width: 100%;
	margin-bottom: 10px;
}
.SupportDetail .row .message {
	background-color: #f5f5f5;
    border: 1px solid rgba(86,61,124,.2);
    padding: 19px;
}
.SupportDetail .message {
    padding: 15px;
    background-color: #f5f5f5;
    border: 1px solid rgba(86,61,124,.2);
    white-space: pre-line;
}
.SupportDetail .lblTicket {
	text-align: right;
}
.SupportDetail .row .userData {
	padding: 19px;
}
.SupportDetail .row.support .userData {
	float: right;
}
@media (max-width: 991px) {
	.SupportDetail .commentsTitle [class^=col-]{
		text-align: center;
	}
	.SupportDetail .commentsTitle [class^=col-] .btn{
		margin-bottom: 5px;
	}
	.SupportDetail .row.support .userData {
		float: none;
	}
	.SupportDetail .lblTicket {
		text-align: left;
	}
}
