.Support .tickets {
  margin-top: 15px;
}

@media (max-width: 767px) {
	.Support .row [class^=col-]{
		text-align: center;
	}
	.Support table thead tr th:nth-child(1),
	.Support table thead tr th:nth-child(3),
	.Support table thead tr th:nth-child(4),
	.Support table tbody tr td:nth-child(1),
	.Support table tbody tr td:nth-child(3),
	.Support table tbody tr td:nth-child(4)
	 {
		width: 0;
	}
}
