.Analysis .help {
	float: right;
	cursor: pointer;
}

.Analysis .form-inline {
	margin-bottom: 20px;
}

.Select {
	display: inline-block;
  width: 300px;
  vertical-align: middle;
}
.AppDatePicker {
	display: inline-block;
}
